import React, { useEffect } from 'react';
import './OurStory.css';
import presidentImage from '../assets/president.png';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

const About = () => {
  useEffect(() => {
    // Initialize AOS for animations
    AOS.init({
      duration: 1000,      // Sets the animation duration to 1 second
      easing: 'ease-in-out', // Easing for smooth transitions
      once: false,         // Animation will trigger every time it scrolls into view
    });

    // Refresh AOS on re-render if needed
    AOS.refresh();
  }, []); // Empty dependency array ensures this only runs on initial mount

  return (
    <div className="about-page">
      {/* Header Section */}
      <div className="about-header" data-aos="fade-up">
        <h1>About Us</h1>
        <p>Learn about our story, vision, and core values.</p>
      </div>

      {/* Our Story Section */}
      <div className="about-section" data-aos="fade-up">
        <h2>Our Story</h2>
        <div className="about-description">
          <p>
            Bangalore Bible Institute & College (BBI&C) was founded in 1994 by Rev. Dr. F.H. Sheikh with the vision to plant churches and train leaders across India. Initially operating as a missionary center, BBI&C began its journey with seven students and three faculty members. Over the years, through God's provision, we expanded our training facilities and now provide a comprehensive theological education to students from across India and abroad.
          </p>
          <p>
            Our commitment to training leaders has been further strengthened by our correspondence courses and evening classes, offering opportunities for local pastors, lay ministers, and others committed to ministry to further their theological education.
          </p>
          <p>
            With a strong foundation in Evangelical doctrine, BBI&C places emphasis on practical evangelism, spiritual growth, and holiness. We continue to equip students for leadership roles in churches, ministries, and community services.
          </p>
        </div>
      </div>

      {/* President Card Section */}
      <div className="about-section" data-aos="fade-up">
        <h2>President</h2>
        <div className="president-card">
          <img
            src={presidentImage}
            alt="Rev. Dr. F.H. Sheikh"
            className="president-image-card"
            data-aos="zoom-in"
            data-aos-delay="100"  // Delay for staggered animations
          />
          <div className="president-description" data-aos="fade-up" data-aos-delay="200">
            <p><strong>Rev. Dr. F.H. Sheikh</strong></p>
            <p>
              Founder of Bangalore Bible Institute & College, Rev. Dr. F.H. Sheikh has been a guiding force in the vision to train and equip Christian leaders. His leadership has inspired many young people to pursue ministry and serve God across India.
            </p>
          </div>
        </div>
      </div>

      {/* Objectives Section */}
      <div className="about-section" data-aos="fade-up">
        <h2>Objectives</h2>
        <p>
          BBI&C is dedicated to equipping leaders for effective ministry. We emphasize the importance of a spirit-filled life, personal holiness, and practical service to God and community. Our students are empowered to lead with integrity, compassion, and faithfulness.
        </p>
      </div>

      {/* Ethos Section */}
      <div className="about-section" data-aos="fade-up">
        <h2>Ethos</h2>
        <p>
          We expect our students to demonstrate a deep commitment to Jesus Christ, evident through their moral integrity, honesty, purity, and love. These values manifest in abstinence from illicit substances and behaviors, and the pursuit of a lifestyle that reflects Christ-like leadership.
        </p>
      </div>

      {/* Doctrines Section */}
      <div className="about-section" data-aos="fade-up">
        <h2>Doctrines</h2>
        <ul>
          <li><strong>The Bible</strong>: Inspired and authoritative Word of God.</li>
          <li><strong>The Trinity</strong>: Father, Son, and Holy Spirit as one God in three persons.</li>
          <li><strong>Jesus Christ</strong>: The deity of Jesus Christ, His virgin birth, sinlessness, atoning death, bodily resurrection, and imminent return.</li>
          <li><strong>Salvation</strong>: Salvation by grace through faith in Jesus Christ.</li>
          <li><strong>The Church</strong>: The Church is the Body of Christ, united under His Headship.</li>
        </ul>
      </div>
    </div>
  );
};

export default About;
