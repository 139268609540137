import React from 'react';
import './AdmissionHero.css'; // Import the CSS for your Hero section
import heroImage1 from '../assets/admission/slide1.jpg'; // Correct the paths for images
import heroImage2 from '../assets/admission/slide2.jpg';
import heroImage3 from '../assets/admission/slide3.jpg'; // More images as needed
import heroImage4 from '../assets/admission/slide4.jpg';
import heroImage5 from '../assets/admission/slide5.jpg';
import heroImage6 from '../assets/admission/slide6.jpg';
import heroImage7 from '../assets/admission/slide7.jpg';

import brochure from '../assets/brochure.pdf'; // Import the PDF brochure

const AdmissionHero = () => {
  const images = [heroImage1, heroImage2, heroImage3, heroImage4, heroImage5, heroImage6, heroImage7];

  return (
    <div className="hero-section">
      <div className="parallax-bg">
        {images.map((image, index) => (
          <div
            key={index}
            className="parallax-image"
            style={{ backgroundImage: `url(${image})` }}
          ></div>
        ))}
        {/* Duplicate the images to ensure continuous sliding without gaps */}
        {images.map((image, index) => (
          <div
            key={index + images.length}
            className="parallax-image"
            style={{ backgroundImage: `url(${image})` }}
          ></div>
        ))}
      </div>

      <div className="hero-content">
        <h1 className="hero-header">Start Your Journey in Theology</h1>
        <p className="hero-subheader">
          Join a community of learners dedicated to spiritual growth and academic excellence. Begin your application today!
        </p>
        <a href={brochure} download className="cta-button">
          Download Admission Brochure
        </a>
      </div>
    </div>
  );
};

export default AdmissionHero;
