import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom"; // For navigation
import "./FeaturedPrograms.css";
import program1 from "../assets/bth.jpg"; // Example images
import program2 from "../assets/dth.jpeg";
import program3 from "../assets/mdiv.jpg";
import nataLogo from "../assets/nata.png"; // Logo image
import qrImage from "../assets/qr.jpg"; // QR code image
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS CSS

const FeaturedPrograms = () => {
  const navigate = useNavigate();

  const programs = [
    {
      image: program1,
      title: "Bachelor of Theology",
      description:
        "This 3-year course for fresh Students. 24 subjects each 3 credit unit or 2 subjects 112 credit hours.",
    },
    {
      image: program2,
      title: "Master of Theology",
      description:
        "(In mission / intercultural studies) 2 Years areas of specialization counselling,... Anthropology of the mission and comparative religions.",
    },
    {
      image: program3,
      title: "Doctor of Ministry",
      description:
        "2 years, regular, correspondence or distance, online 1st year 8 subjects + Thesis Research / Dissertation, 150 pages, selected areas of the theme and ministries.",
    },
  ];

  const handleMoreClick = () => {
    navigate("/courses"); // Navigates to the About page
  };

  useEffect(() => {
    // Initialize AOS for animation
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: false, // Set to true to trigger animation once
    });

    // Optional: Refresh AOS when the component is re-rendered
    AOS.refresh();
  }, []);

  return (
    <div className="featured-programs">
      {/* Welcome Title and Description */}
      <div className="welcome-section" data-aos="fade-up">
        <h3>Welcome to Bangalore Bible Institute and College</h3>
        <p>
          At Bangalore Bible Institute and College, we are dedicated to providing a robust and flexible online learning environment that nurtures spiritual growth and academic excellence. Our programs are designed to equip you with a deep understanding of theological studies, enabling you to serve your community and grow in your faith, all from the comfort of your home. We invite you to explore our range of online courses and join our vibrant community of learners who are committed to studying the Word of God and applying its teachings to everyday life.
        </p>
      </div>

      {/* Featured Programs Section */}
      <h2 className="featured-programs-header" data-aos="fade-up" data-aos-delay="100">
        Featured Programs
      </h2>
      <div className="featured-programs-cards">
        {programs.map((program, index) => (
          <div key={index} className="program-card" data-aos="zoom-in" data-aos-delay={`${index * 200}`}>
            <img src={program.image} alt={program.title} className="program-card-image" />
            <h3 className="program-card-title">{program.title}</h3>
            <p className="program-card-description">{program.description}</p>
          </div>
        ))}
      </div>
      <button className="more-programs-button" onClick={handleMoreClick} data-aos="fade-up" data-aos-delay="300">
        More
      </button>

      {/* Additional Info Section */}
      <div className="extra-program-info">
        <div className="academic-program-section" data-aos="fade-up" data-aos-delay="400">
          <h3>Academic Programme</h3>
          <p>
            The academic programme of the Institute is distinctly Bible Centered and adapted for imparting training in practical theology. The main subjects that are taught are those that prepare candidates for the Certificate in Theology, Diploma in Theology (D.Th), Graduate in Theology (G.Th), Bachelor of Theology (B.Th), Bachelor of Divinity, Master of Divinity (M.Div), Master of Theology (M.Th), Doctor of Theology in Mission (Th.D), Doctor of Ministry (D.Min), and D.Th, BA, and M.A in Islamic theology by Institution on Islam and Mission (CIBS).
          </p>
        </div>

        <div className="course-nature-section" data-aos="fade-up" data-aos-delay="500">
          <h3>Nature of the Course</h3>
          <p>
            Certificate, Diploma, and Graduate in Theology and Degrees program aims at providing a basic understanding of the Bible and the Christian Faith and witness in both historical and contemporary contexts, giving particular emphasis to be practical aspects of the Christian ministry.
          </p>
        </div>

        <div className="affiliated-institutions-section" data-aos="fade-up" data-aos-delay="600">
  <h3>Accreditation</h3>
  <p>
            Trinity Theological Open University & DIAM International University & Evangelical Institutions and Colleges of India & Abroad
          </p>
  <div className="nata-section">
    <img src={nataLogo} alt="NATA Logo" className="nata-logo" />
    <p className="nata-text">Nations Association for Theological Accreditation</p>
  </div>
  <div className="qr-code-section">
    <h5 className="qr-title">Autonomous</h5>
    <img src={qrImage} alt="QR Code" className="qr-code-image-large" />
  </div>
</div>

      </div>
    </div>
  );
};

export default FeaturedPrograms;
