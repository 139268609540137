import React, { useState, useEffect } from 'react';
import './Gallery.css';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

const Gallery = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  // Array of 30 images (replace with your actual images if needed)
  const images = [
    require('../assets/gallery/001.JPG'),
    require('../assets/gallery/002.JPG'),
    require('../assets/gallery/003.JPG'),
    require('../assets/gallery/005.JPG'),
    require('../assets/gallery/006.JPG'),
    require('../assets/gallery/007.JPG'),
    require('../assets/gallery/008.JPG'),
    require('../assets/gallery/009.JPG'),
    require('../assets/gallery/010.JPG'),
    require('../assets/gallery/011.JPG'),
    require('../assets/gallery/012.JPG'),
    require('../assets/gallery/013.JPG'),
    require('../assets/gallery/015.JPG'),
    require('../assets/gallery/016.JPG'),
    require('../assets/gallery/017.JPG'),
    require('../assets/gallery/018.JPG'),
    require('../assets/gallery/020.JPG'),
    require('../assets/gallery/021.JPG'),
    require('../assets/gallery/022.JPG'),
    require('../assets/gallery/023.JPG'),
    require('../assets/gallery/024.JPG'),
    require('../assets/gallery/025.JPG'),
    require('../assets/gallery/026.JPG'),
    require('../assets/gallery/028.JPG'),
    require('../assets/gallery/029.JPG'),
    require('../assets/gallery/030.JPG')
  ];

  useEffect(() => {
    // Initialize AOS for animations
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true, // Animation happens only once
    });

    // Optional: Refresh AOS on re-render
    AOS.refresh();
  }, []);

  const openModal = (index) => {
    setIsOpen(true); // Open the modal
    setCurrentImageIndex(index); // Set the current image index for preview
  };

  const closeModal = () => {
    setIsOpen(false); // Close the modal
    setCurrentImageIndex(null); // Reset the current image index
  };

  const nextImage = () => {
    setCurrentImageIndex((prev) => (prev + 1) % images.length); // Go to next image
  };

  const prevImage = () => {
    setCurrentImageIndex((prev) =>
      prev === 0 ? images.length - 1 : prev - 1
    ); // Go to previous image
  };

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev); // Toggle expand/collapse images
  };

  return (
    <div className="gallery-section">
      <h2 data-aos="fade-up">Gallery & Media</h2>
      <p data-aos="fade-up" data-aos-delay="200">
        Although BBI&C is an online institution, our community is active and thriving in the digital space. Here’s a look at some of our virtual events, online graduations, and workshops.
      </p>

      {/* Grid of Images */}
      <div className="gallery">
        {images.slice(0, isExpanded ? images.length : 5).map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Gallery Image ${index + 1}`}
            onClick={() => openModal(index)} // OnClick opens the modal with the image
            data-aos="zoom-in"
            data-aos-delay={`${index * 100}`} // Staggered animation delays
          />
        ))}
      </div>

      {/* Show "More" Button */}
      <div className="more-button">
        <button onClick={toggleExpand}>
          {isExpanded ? 'Show Less' : 'Show More'}
        </button>
      </div>

      {/* Modal for Enlarged Image */}
      {isOpen && (
        <div className={`modal ${isOpen ? 'open' : ''}`} onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="prev" onClick={prevImage}>❮</button>
            <img
              src={images[currentImageIndex]}
              alt={`Large View ${currentImageIndex + 1}`}
              className="modal-image"
              data-aos="fade-up"
            />
            <button className="next" onClick={nextImage}>❯</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
