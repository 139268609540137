import React, { useEffect } from 'react';
import AdmissionHero from '../components/AdmissionHero';
import './Admission.css'; 
import FAQ from '../components/FAQ';
import Footer from '../components/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import AdmissionForm from '../assets/Admission_Form.pdf';

const Admission = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,    
      easing: 'ease-in-out', 
      once: false,        
    });
  }, []);

  return (
    <div className="admission-page">
      {/* Hero Section */}
      <AdmissionHero />

      {/* Main Content Wrapper */}
      <div className="main-content-wrapper">

        {/* Welcome Section */}
        <section className="welcome-section" data-aos="fade-up">
          <h1 className="welcome-title">Welcome to BBIC Admission</h1>
          <p className="welcome-description">
            Explore a variety of programs and take the first step towards shaping your future with us. 
            Our admission process is designed to be simple and seamless, guiding you every step of the way.
          </p>
        </section>

        {/* Featured Courses Section */}
        <section className="featured-courses-section" data-aos="fade-up">
          <h2 className="section-title">Featured Courses</h2>
          <div className="featured-courses">
            <div className="course-card" data-aos="fade-up" data-aos-delay="100">
              <h3>B.A in Islamic Theology</h3>
              <p>A comprehensive undergraduate program in Islamic theology.</p>
            </div>
            <div className="course-card" data-aos="fade-up" data-aos-delay="200">
              <h3>M.A in Islamic Theology</h3>
              <p>Advanced studies in Islamic theology with a focus on mission.</p>
            </div>
            <div className="course-card" data-aos="fade-up" data-aos-delay="300">
              <h3>D.Th in Mission</h3>
              <p>A doctoral program focused on practical and academic aspects of mission.</p>
            </div>
          </div>
        </section>

        {/* Our Programs Table Section */}
        <section className="programs-section" data-aos="fade-up">
          <h2 className="section-title">Our Programs</h2>
          <table className="programs-table">
            <thead>
              <tr>
                <th>Course Name</th>
                <th>Qualifications</th>
                <th>Duration</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>C.Th</td>
                <td>8th Class Pass</td>
                <td>6 Months</td>
              </tr>
              <tr>
                <td>D.Th in Islamic Theology & Mission</td>
                <td>SSLC / C.Th</td>
                <td>1 Year</td>
              </tr>
              <tr>
                <td>B.A in Islamic Theology & Mission</td>
                <td>PUC</td>
                <td>2 Years</td>
              </tr>
              <tr>
                <td>M.A in Islamic Theology & Mission</td>
                <td>B.Th, B.A</td>
                <td>2 Years</td>
              </tr>
              <tr>
                <td>G.Th</td>
                <td>SSLC Pass / D.Th</td>
                <td>2 Years</td>
              </tr>
              <tr>
                <td>B.Th</td>
                <td>PUC</td>
                <td>3 Years</td>
              </tr>
              <tr>
                <td>M.Div</td>
                <td>B.Th, B.D</td>
                <td>2 Years</td>
              </tr>
              <tr>
                <td>M.Th</td>
                <td>M.Div</td>
                <td>2 Years</td>
              </tr>
              <tr>
                <td>D.Min</td>
                <td>M.Div, M.Th</td>
                <td>2 Years</td>
              </tr>
              <tr>
                <td>Th.D in Mission</td>
                <td>M.Div</td>
                <td>2 Years</td>
              </tr>
              <tr>
                <td>D.D Honorary Degree & Ph.D</td>
                <td>10 Years Church & Mission Experience</td>
                <td>2 Years</td>
              </tr>
            </tbody>
          </table>
        </section>

        {/* Admission Requirements Section */}
        <section className="admission-requirements" data-aos="fade-up">
          <h2 className="admission-requirements-title">Admission Requirements</h2>
          <p className="section-description">
            To apply, ensure that you meet the program-specific requirements and submit the necessary documents.
          </p>
          <ul className="admission-requirements-list">
            <li>Photo copies of all Educational Documents</li>
            <li>Personal Testimony</li>
            <li>Church Leader Recommendation Letter</li>
            <li>2 Passport sized Photos</li>
            <li>Aadhar card photo copy</li>
            <li>Official transcripts from previous institutions</li>
            <li>Attach the admission form</li>
          </ul>
          <a href={AdmissionForm} download className="download-form-button">
            Download Admission Form
          </a>
        </section>

        <FAQ />

      </div>
      <Footer />
    </div>
  );
};

export default Admission;
