import React from 'react';
import HeroSection from '../components/HeroSection';  // Import the HeroSection component
import FeaturedPrograms from '../components/FeaturedPrograms';
import Footer from '../components/Footer';
// import AutonomousCard from '../components/AutonomusCard';

const Home = () => {
    return (
        <div>
            {/* Hero Section */}
            <HeroSection />
            <FeaturedPrograms />
            {/* <AutonomousCard /> */}
            <Footer />

        </div>
    );
};

export default Home;
