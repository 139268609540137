import React, { useEffect } from 'react';
import './Courses.css';
import Footer from '../components/Footer'; 
import ContactForm from '../components/ContactForm';
import brochure from '../assets/brochure.pdf';

import program1 from '../assets/bth.jpg'; 
import program2 from '../assets/dth.jpeg';
import program3 from '../assets/mdiv.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

const FeaturedPrograms = () => {
    const programs = [
        {
            image: program1,
            title: 'Bachelor of Theology',
            description: 'This 3-year course for fresh Students. 24 subjects each 3 credit unit or 2 subjects 112 credit hours.',
        },
        {
            image: program2,
            title: 'Master of Theology',
            description: '(In mission/intercultural studies) 2 Years areas of specialization counselling, anthropology of the mission, and comparative religions.',
        },
        {
            image: program3,
            title: 'Doctor of Ministry',
            description: '2 years, regular, correspondence or distance, online 1st year 8 subjects + Thesis Research / Dissertation, 150 pages, selected areas of the theme and ministries.',
        },
    ];

    return (
        <div className="featured-programs-section">
            <h2 className="featured-programs-header" data-aos="fade-up">Featured Programs</h2>
            <div className="featured-programs-cards">
                {programs.map((program, index) => (
                    <div className="program-card" key={index} data-aos="fade-up" data-aos-delay={`${index * 100}`}>
                        <img src={program.image} alt={program.title} className="program-card-image" />
                        <h3 className="program-card-title">{program.title}</h3>
                        <p className="program-card-description">{program.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

const getQualifications = (course) => {
    switch (course) {
        case "C.Th": return "8th Class Pass";
        case "D.Th in Islamic Theology & Mission": return "SSLC / C.Th";
        case "B.A in Islamic Theology & Mission": return "PUC";
        case "M.A in Islamic Theology & Mission": return "B.Th, B.A";
        case "G.Th": return "SSLC Pass / D.Th";
        case "B.Th": return "PUC";
        case "M.Div": return "B.Th, B.D";
        case "M.Th": return "M.Div";
        case "D.Min": return "M.Div, M.Th";
        case "Th.D in Mission": return "M.Div";
        case "D.D Honorary Degree & Ph.D": return "10 Years Mission Experience";
        default: return "";
    }
};

const getDuration = (course) => {
    switch (course) {
        case "C.Th": return "6 Months";
        case "D.Th in Islamic Theology & Mission": return "1 Year";
        case "B.A in Islamic Theology & Mission": return "2 Years";
        case "M.A in Islamic Theology & Mission": return "2 Years";
        case "G.Th": return "2 Years";
        case "B.Th": return "3 Years";
        case "M.Div": return "2 Years";
        case "M.Th": return "2 Years";
        case "D.Min": return "2 Years";
        case "Th.D in Mission": return "2 Years";
        case "D.D Honorary Degree & Ph.D": return "2 Years";
        default: return "";
    }
};

const getOverview = (course) => {
    switch (course) {
        case "C.Th": 
            return "The Certificate in Theology (C.Th) is a foundational program designed to introduce students to the basics of theology, biblical studies, and church history, with a focus on developing a personal relationship with God.";
        case "D.Th in Islamic Theology & Mission":
            return "The Diploma in Theology (D.Th) in Islamic Theology & Mission prepares students with a solid grounding in Islamic theology and its application in Christian mission contexts, equipping them to work in multicultural environments.";
        case "B.A in Islamic Theology & Mission":
            return "The Bachelor of Arts in Islamic Theology & Mission offers a comprehensive understanding of Islamic beliefs and practices, combined with Christian theology, focusing on interfaith dialogue and missionary work in diverse cultures.";
        case "M.A in Islamic Theology & Mission":
            return "The Master of Arts in Islamic Theology & Mission is designed for those seeking advanced knowledge in Islamic theology while preparing them for leadership roles in missionary work, focusing on cross-cultural contexts and interfaith dialogue.";
        case "G.Th":
            return "The Graduate Diploma in Theology (G.Th) is for individuals with previous theological training, offering a deeper study in theology, biblical interpretation, and church leadership.";
        case "B.Th":
            return "The Bachelor of Theology (B.Th) program provides an in-depth study of Christian theology, preparing students for ministry leadership, biblical teaching, and pastoral care.";
        case "M.Div":
            return "The Master of Divinity (M.Div) program is a comprehensive professional degree that prepares individuals for pastoral ministry, counseling, and leadership within the church or Christian organizations.";
        case "M.Th":
            return "The Master of Theology (M.Th) program is designed for students seeking advanced academic training in theology, with opportunities for research in a wide range of theological topics.";
        case "D.Min":
            return "The Doctor of Ministry (D.Min) is a professional doctorate for ministry leaders who wish to deepen their understanding of theology while enhancing their practical leadership skills in ministry settings.";
        case "Th.D in Mission":
            return "The Doctor of Theology (Th.D) in Mission focuses on advanced theological studies in missions, preparing scholars and practitioners for high-level work in global missions, church planting, and cross-cultural ministry.";
        case "D.D Honorary Degree":
            return "The Doctor of Divinity (D.D) Honorary Degree is awarded to individuals who have demonstrated exceptional service and leadership in church ministry or Christian mission work.";
        default:
            return "This is a detailed theological program aimed at preparing students for a variety of ministry and leadership roles in the church and beyond.";
    }
};

const Courses = () => {
    const courses = [
        "C.Th",
        "D.Th in Islamic Theology & Mission",
        "B.A in Islamic Theology & Mission",
        "M.A in Islamic Theology & Mission",
        "G.Th",
        "B.Th",
        "M.Div",
        "M.Th",
        "D.Min",
        "Th.D in Mission",
        "D.D Honorary Degree & Ph.D"
    ];

    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: 'ease-in-out',
            once: false,  // Change this to false so that the animation triggers every time the element comes into view
        });
    }, []);

    return (
        <div>
            <div className="site-wrapper">
                <div className="course-introduction" data-aos="fade-up">
                    <div className="course-intro-header">
                        <h2 className="course-intro-header-title">Welcome to Our Courses</h2>
                        <p>Start your learning journey today and take the first step towards a brighter future.</p>
                    </div>
                    <div className="course-intro-details">
                        <h3>What We Offer</h3>
                        <p>
                            Our courses are designed to help you achieve your personal and professional goals.
                            Whether you're just starting or looking to enhance your existing skills, we offer a
                            wide variety of online courses tailored to meet your needs.
                        </p>
                        <div className="download-brochure">
                            <a href={brochure} download="Brochure.pdf" className="download-button">
                                Download Brochure
                            </a>
                        </div>
                    </div>
                </div>
                <FeaturedPrograms />

                <div className="course-table" data-aos="fade-up">
                    <h2 className="courses-title">Courses We Offer</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Course Name</th>
                                <th>Qualifications</th>
                                <th>Duration</th>
                                <th>Overview</th>
                            </tr>
                        </thead>
                        <tbody>
                            {courses.map((course) => (
                                <tr key={course}>
                                    <td>{course}</td>
                                    <td>{getQualifications(course)}</td>
                                    <td>{getDuration(course)}</td>
                                    <td>{getOverview(course)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="faculty-section" data-aos="fade-up">
                    <h2 className="faculty-header">Faculty Members</h2>
                    <table className="faculty-table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Designation</th>
                                <th>Qualification</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr><td>Rev. Dr. F.H Sheikh</td><td>President</td><td>PhD</td></tr>
                            <tr><td>Rev. Dr. Jose Thayanithi</td><td>Vice President</td><td>M.A, M.Th, Th.D</td></tr>
                            <tr><td>Rev. SMA Bernad</td><td>Lecturer</td><td>M.A, Relg, B.D, M.A, D.D</td></tr>
                            <tr><td>Prof. Manoj Kumar Thami</td><td>Professor</td><td>M.Th</td></tr>
                            <tr><td>Rev. Diamond Donald</td><td>Professor</td><td>B.D, M.Th</td></tr>
                            <tr><td>Prof. Dr. Manasse Desai</td><td>Registrar</td><td>M.Th, D.D</td></tr>
                            <tr><td>Prof. Norman</td><td>Professor</td><td>B.D, M.Div, M.Th</td></tr>
                            <tr><td>Prof. Anthony Luize</td><td>Professor</td><td>M.A, D.D</td></tr>
                            <tr><td>Prof. Nathanel Nayak</td><td>Dean of Program</td><td>M.Div, D.Min</td></tr>
                            <tr><td>Prof. Premraj Nag</td><td>Professor</td><td>M.Th, Ph.D</td></tr>
                        </tbody>
                    </table>
                </div>

                <div className="course-testimonials" data-aos="fade-up">
                    <h3>Student Testimonials</h3>
                    <div className="testimonial">
                        <p>"The B.Th program transformed my understanding of theology. The faculty was outstanding!" - <strong>Raj</strong></p>
                    </div>
                    <div className="testimonial">
                        <p>"The online learning experience was seamless, and the course content was extremely relevant to my career goals." - <strong>Rana</strong></p>
                    </div>
                </div>

                <div className="course-enrollment" data-aos="fade-up">
                    <div className="practical-work-programme">
                        <h3>Practical Work Programme & Extracurricular Activities</h3>
                        <p>Students are provided with opportunities for involvement in practical work, during the period of their training in various ways. It is required of all students that they avail themselves of these opportunities.</p>
                        <p>Under the direction of a faculty committee, activities such as conducting Sunday Schools in villages, house visitations, personal evangelism, youth meetings, and church activities in teaching and preaching ministry will be assigned.</p>
                        <p>During the summer vacations, students are encouraged to take initiative and participate in Christian service activities such as VBS schools or cooperating with Christian agencies in various works. Every student is required to spend at least one month during the summer in practical ministry.</p>
                        <p>BBI & C ensures students get personal attention from faculty, assigning each student a faculty member for sharing and counseling. The faculty-student relationship is warm and cordial</p>
                        <p></p>
                    </div>

                    <div className="graduation-conditions">
                        <h3>Conditions For Graduation</h3>
                        <p>To qualify for Certificate, Diploma & Graduate in Theology and Degrees, a candidate must pass in subjects and in all three to nine semesters. The pass mark for all papers is 60%, and the graduation marks for all papers must average 80%. The grade for each course is based on the average marks obtained for each term, with the term mark considering class work, reading, written assignments, and examinations.</p>
                    </div>
                </div>
            </div>

            <ContactForm />
            <Footer />
        </div>
    );
};

export default Courses;
