import React from 'react';
import OurStory from '../components/OurStory'
import Footer from '../components/Footer'
import Gallery from '../components/Gallery';
import FAQ from '../components/FAQ';
import Accreditation from '../components/Accreditation';

const About = () => {
    return (
        <div className="about-page">
            <OurStory />
            <Gallery />
            <FAQ />
            <Accreditation />
            <Footer />
            

            
            
        </div>
    );
};

export default About;
