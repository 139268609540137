import React, { useEffect } from 'react';
import './Accreditation.css'; // Import the CSS specific to the Accreditation component
import nataLogo from '../assets/nata.png'; // Import the image directly
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

const Accreditation = () => {
  useEffect(() => {
    // Initialize AOS for animations
    AOS.init({
      duration: 1000, // Duration for animations
      easing: 'ease-in-out', // Easing function
      once: true, // Animation happens only once
    });

    // Optional: Refresh AOS on re-render
    AOS.refresh();
  }, []);

  return (
    <div className="accreditation-section">
      <h2 data-aos="fade-up">Our Accreditation</h2>
      <p data-aos="fade-up" data-aos-delay="100">We are accredited by:</p>
      <div className="accreditation-card" data-aos="fade-up" data-aos-delay="200">
        <div className="accreditation-logo">
          <img src={nataLogo} alt="NATA Accreditation" />
        </div>
        <div className="accreditation-info">
          <p><strong>Nations Association for Theological Accreditation</strong></p>
          {/* <p>Accredited to provide quality services in the field of testing and certification.</p> */}
        </div>
      </div>
    </div>
  );
};

export default Accreditation;
