import React from "react";
import "./Footer.css";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa"; // Import social media icons
import qrImage from "../assets/qr.jpg"; // Import QR code image

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h3 className="footer-title">Bangalore Bible Institute & College</h3>
          <p className="footer-description">
            Empowering future leaders through theology and spiritual growth. Join us in our mission to make a difference!
          </p>
        </div>

        <div className="footer-section">
          <h4>Contact Us</h4>
          <ul className="footer-list">
            <li>
              Email:{" "}
              <a href="mailto:bangalorebibleinstitute.college@gmail.com">
                bangalorebibleinstitute.college@gmail.com
              </a>
            </li>
            <li>
              Phone: <a href="tel:+91 9972426386">+91 9972426386</a>
            </li>
            <li>
              Address: Building No. 2, 1st cross, Pillappa Layout Chelikre, Kalyan
              Nagar - Bangalore 560043, India
            </li>
          </ul>
        </div>

        <div className="footer-section">
          <h4>Follow Us</h4>
          <div className="footer-social-media">
            <a
              href="https://facebook.com"
              className="social-icon"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook />
            </a>
            <a
              href="https://twitter.com"
              className="social-icon"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter />
            </a>
            <a
              href="https://instagram.com"
              className="social-icon"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </a>
          </div>
          {/* <div className="qr-code-section">
            <h5 className="qr-title">Autonomous</h5>
            <img src={qrImage} alt="QR Code" className="qr-code-image" />
          </div> */}
        </div>
      </div>
      <div className="footer-bottom">
        <p>
          &copy; 2024 Bangalore Bible Institute & College. All Rights Reserved. |{" "}
          <a href="https://tirkey.in" target="_blank" rel="noopener noreferrer">
            tirkey.in
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
