import React, { useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import './ContactForm.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
        course: '',
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        // Initialize AOS for animations
        AOS.init({
            duration: 1000,
            easing: 'ease-in-out',
            once: true,
        });

        // Refresh AOS on re-renders
        AOS.refresh();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validate = () => {
        const formErrors = {};
        if (!formData.name) formErrors.name = 'Name is required';
        if (!formData.email) formErrors.email = 'Email is required';
        if (!formData.phone) formErrors.phone = 'Phone number is required';
        if (!formData.message) formErrors.message = 'Message cannot be empty';
        if (!formData.course) formErrors.course = 'Please select a course of interest';
        return formErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formErrors = validate();

        if (Object.keys(formErrors).length === 0) {
            emailjs
                .send(
                    'service', // Service ID
                    'template', // Template ID
                    {
                        name: formData.name,
                        email: formData.email,
                        phone: formData.phone,
                        message: formData.message,
                        course: formData.course,
                    },
                    'x6MJ1SvQE6q8tjEu9' // Public Key
                )
                .then(
                    (response) => {
                        console.log('Email sent successfully:', response.status, response.text);
                        alert('Your inquiry has been submitted. Thank you!');
                        setFormData({
                            name: '',
                            email: '',
                            phone: '',
                            message: '',
                            course: '',
                        });
                        setErrors({});
                    },
                    (error) => {
                        console.error('Email send failed:', error);
                        alert(`Failed to send your inquiry. Error: ${error.text || 'Unknown error'}`);
                    }
                );
        } else {
            setErrors(formErrors);
        }
    };

    return (
        <div className="contact-form-container">
            <h2 className="contact-form-title" data-aos="fade-up">Contact Us</h2>
            <p className="contact-form-description" data-aos="fade-up" data-aos-delay="100">
                Have questions about our courses or admissions? Fill out the form below, and we'll get back to you!
            </p>
            <form className="contact-form" onSubmit={handleSubmit}>
                <label data-aos="fade-up" data-aos-delay="200">
                    Full Name:
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Your Name"
                        required
                    />
                    {errors.name && <p className="error-message">{errors.name}</p>}
                </label>

                <label data-aos="fade-up" data-aos-delay="300">
                    Email:
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Your Email"
                        required
                    />
                    {errors.email && <p className="error-message">{errors.email}</p>}
                </label>

                <label data-aos="fade-up" data-aos-delay="400">
                    Phone:
                    <input
                        type="tel"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        placeholder="Your Phone Number"
                        required
                    />
                    {errors.phone && <p className="error-message">{errors.phone}</p>}
                </label>

                <label data-aos="fade-up" data-aos-delay="500">
                    Course of Interest:
                    <select
                        name="course"
                        value={formData.course}
                        onChange={handleChange}
                        required
                    >
                        <option value="" disabled>Select a Course</option>
                        <option value="C.Th">C.Th - Certificate in Theology (6 Months)</option>
                        <option value="D.Th Islamic Theology & Mission">
                            D.Th - Islamic Theology & Mission (1 Year)
                        </option>
                        <option value="B.A Islamic Theology & Mission">
                            B.A - Islamic Theology & Mission (3 Years)
                        </option>
                        <option value="M.A Islamic Theology & Mission">
                            M.A - Islamic Theology & Mission (2 Years)
                        </option>
                        <option value="G.Th">G.Th - Graduate Diploma in Theology (2 Years)</option>
                        <option value="B.Th">B.Th - Bachelor of Theology (3 Years)</option>
                        <option value="M.Div">M.Div - Master of Divinity (2 Years)</option>
                        <option value="M.Th">M.Th - Master of Theology (2 Years)</option>
                        <option value="D.Min">D.Min - Doctor of Ministry (2 Years)</option>
                        <option value="Th.D in Mission">Th.D - Theology in Mission (2 Years)</option>
                        <option value="D.D Honorary Degree">
                            D.D - Honorary Degree (Experience-Based)
                        </option>
                    </select>
                    {errors.course && <p className="error-message">{errors.course}</p>}
                </label>

                <label data-aos="fade-up" data-aos-delay="600">
                    Message:
                    <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        placeholder="Your Questions or Comments"
                        required
                    />
                    {errors.message && <p className="error-message">{errors.message}</p>}
                </label>

                <button type="submit" className="submit-button" data-aos="fade-up" data-aos-delay="700">
                    Submit
                </button>
            </form>
        </div>
    );
};

export default ContactForm;
