import React, { useState, useEffect } from 'react';
import './Contact.css'; // Updated CSS file for contact page styling
import Footer from '../components/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import emailjs from '@emailjs/browser';

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        'service', // Replace with your Service ID
        'template', // Replace with your Template ID
        {
          name: formData.name,
          email: formData.email,
          message: formData.message,
        },
        'x6MJ1SvQE6q8tjEu9' // Replace with your Public Key
      )
      .then(
        (response) => {
          console.log('Message sent successfully:', response.status, response.text);
          alert('Message sent successfully! We will get back to you shortly.');
          setFormData({ name: '', email: '', message: '' }); // Reset form fields
        },
        (error) => {
          console.error('Failed to send message:', error);
          alert('Failed to send your message. Please try again later.');
        }
      );
  };

  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: false, // Set to true if you want the animations to run only once
    });

    // Optional: Refresh AOS when the component is re-rendered
    AOS.refresh();
  }, []);

  return (
    <div className="contact-page-container">
      <div className="contact-content-container">
        {/* Institute Title */}
        <section className="contact-header-section" data-aos="fade-up">
          <h1 className="contact-title">Bangalore Bible Institute and College</h1>
          <p className="contact-description">Empowering individuals through biblical education and ministry.</p>
        </section>

        {/* Contact Us Section */}
        <section className="contact-us-section" data-aos="fade-up" data-aos-delay="100">
          <h2 className="section-title">Contact Us</h2>
          <p>We would love to hear from you! Please get in touch with any questions or inquiries.</p>
        </section>

        {/* Our Office Section */}
        <section className="contact-info-section" data-aos="fade-up" data-aos-delay="200">
          <h2 className="section-title">Our Office</h2>
          <p><strong>Phone:</strong> +91 99724263686</p>
          <p><strong>Email:</strong> bangalorebibleinstitute.college@gmai.com</p>
          <p><strong>Address:</strong> Kalyan Nagar, Bangalore - 560043, India</p>
        </section>

        {/* Send Us a Message Section */}
        <section className="contact-form-section" data-aos="fade-up" data-aos-delay="300">
          <h2 className="section-title">Send Us a Message</h2>
          <form onSubmit={handleSubmit}>
            <div className="contact-form-group">
              <label htmlFor="name" className="contact-form-label">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="contact-form-input"
                required
              />
            </div>
            <div className="contact-form-group">
              <label htmlFor="email" className="contact-form-label">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="contact-form-input"
                required
              />
            </div>
            <div className="contact-form-group">
              <label htmlFor="message" className="contact-form-label">Message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="contact-form-textarea"
                required
              />
            </div>
            <button type="submit" className="contact-submit-btn">Send Message</button>
          </form>
        </section>
      </div>

      {/* Footer Component */}
      <Footer />
    </div>
  );
};

export default ContactPage;
