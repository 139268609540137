import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import hero1 from '../assets/hero1.JPG';
import hero2 from '../assets/hero2.JPG';
import hero3 from '../assets/hero3.JPG';
import hero4 from '../assets/hero4.JPG';
import hero5 from '../assets/hero5.JPG';
import './HeroSection.css';

const HeroSection = () => {
    const images = [hero1, hero2, hero3, hero4, hero5];

    return (
        <div className="hero-section">
            <div className="parallax-bg">
                {images.map((image, index) => (
                    <div
                        key={index}
                        className="parallax-image"
                        style={{ backgroundImage: `url(${image})` }}
                    ></div>
                ))}
            </div>
            <div className="hero-content">
                <h1 className="hero-header">Welcome to BBIC!</h1>
                <p className="hero-subheader">Building Better Innovation Culture</p>
                {/* Replace anchor with Link */}
                <Link to="/about" className="cta-button">Learn More</Link>
            </div>
        </div>
    );
};

export default HeroSection;
