import React, { useEffect } from 'react';
import './FAQ.css'; // Import the CSS specific to the FAQ component
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

const FAQ = () => {
  useEffect(() => {
    // Initialize AOS for animations
    AOS.init({
      duration: 1000, // Duration for animations
      easing: 'ease-in-out', // Easing function
      once: true, // Animation happens only once
    });

    // Optional: Refresh AOS on re-render
    AOS.refresh();
  }, []);

  return (
    <div className="faq-section">
      <h2 data-aos="fade-up">Frequently Asked Questions</h2>
      <div className="faq">
        <div className="faq-item" data-aos="fade-up" data-aos-delay="100">
          <h3>What is BBI&C?</h3>
          <p>BBI&C is an online institution offering a variety of virtual courses and workshops.</p>
        </div>
        <div className="faq-item" data-aos="fade-up" data-aos-delay="200">
          <h3>How can I enroll in a course?</h3>
          <p>You can enroll by visiting our website and selecting the course you are interested in.</p>
        </div>
        <div className="faq-item" data-aos="fade-up" data-aos-delay="300">
          <h3>Are the courses accredited?</h3>
          <p>Yes, we offer accredited programs recognized by industry professionals.</p>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
